import request from "@/utils/request";
import Vcode from "vue-puzzle-vcode";
// npm list vue



const {ElMessage} = require("element-plus");
export default {
    name: "Login",
    // components: {
    //     Vcode,
    // },
    data() {
        return {
            // beginClientX: 0, /* 距离屏幕左端距离 */
            // mouseMoveStata: false, /* 触发拖动状态  判断 */
            // maxwidth: '', /* 拖动最大宽度，依据滑块宽度算出来的 */
            // confirmWords: '拖动滑块验证', /* 滑块文字 */
            // confirmSuccess: true, /* 验证成功判断 */

            identity: "",
            // showpassword: true,
            // judgeAddOrEdit: true,
            // dialogVisible: false,
            // isShow: true, // 验证码模态框是否出现
            form: {
                username: '',
                password: '',
                gender: '',
                phoneNum: '',
                zhifubao: '',
                yhnumber: '',
                identity: '',
                value: '',
                type: '',
            },
            rules: {
                username: [{required: true, message: "请输入用户名", trigger: "blur"},],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { validator: this.validatePassword, trigger: "blur" }, // 添加自定义验证规则
                    { max: 16, message: "密码不能超过16位字符", trigger: "blur" }, // 添加长度验证规则
                ],
                gender: [{required: true, message: "请选择性别", trigger: "blur"}],
                phoneNum: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { validator: this.validatePhoneNumber, trigger: "blur" }, // 添加自定义验证规则
                ],

                zhifubao: [{required: true, message: "请输入支付宝账号", trigger: "blur"}],
                yhnumber: [
                    { required: true, message: "请输入银行卡号", trigger: "blur" },
                    { validator: this.validateBankCard, trigger: "blur" }, // 添加自定义验证规则
                ],
                value: [
                    { required: true, message: "请输入令牌", trigger: "blur" },
                ],
                // 添加表单验证规则
                type: [
                    { required: true, message: '请选择注册身份', trigger: 'change' }
                ],
                identity: [
                    { required: true, message: '请选择身份', trigger: 'change' }
                ],
                name: [
                    { required: true, message: "请输入姓名", trigger: "blur" },
                ],
            },
        };
    },
    computed: {
        disabled() {
            const {username, password, identity} = this.form;
            return Boolean(username && password && identity);
        },
    },
    methods: {
        // updateIdentityOptions() {
        //     // 根据注册身份的选择，更新身份选择框的选项
        //     if (this.form.type === '员工') {
        //         // 如果选择的是"接单员"
        //         this.form.identity = ''; // 清空已选择的身份
        //         // 设置身份选择框的选项
        //         this.$nextTick(() => {
        //             this.$refs.form.clearValidate('identity'); // 清除验证状态
        //             this.$refs.form.validateField('identity'); // 触发重新验证
        //         });
        //     } else if (this.form.type === '客服') {
        //         // 如果选择的是"派单员"
        //         this.form.identity = '客服'; // 设为"客服"
        //         // 设置身份选择框的选项
        //         this.$nextTick(() => {
        //             this.$refs.form.clearValidate('identity'); // 清除验证状态
        //             this.$refs.form.validateField('identity'); // 触发重新验证
        //         });
        //     }
        // },
        validatePassword(rule, value, callback) {
            const regex = /[^\x00-\xff]+/; // 匹配非汉字字符的正则表达式
            if (!regex.test(value)) {
                callback(); // 密码不包含汉字，验证通过
            } else {
                callback(new Error('密码不能包含汉字')); // 密码包含汉字，验证失败
            }
        },
        validatePhoneNumber(rule, value, callback) {
            const regex = /^[0-9]{11}$/; // 电话号码应该是11位数字
            if (regex.test(value)) {
                callback(); // 验证通过
            } else {
                callback(new Error('手机号格式不正确'));
            }
        },
        validateBankCard(rule, value, callback) {
            // 去除银行卡号中的空格
            value = value.replace(/\s/g, '');

            if (/^\d+$/.test(value)) {
                // 银行卡号只包含数字
                if (value.length >= 15 && value.length <= 19) {
                    // 银行卡号长度在15到19之间
                    callback(); // 验证通过
                } else {
                    callback(new Error('银行卡号长度不符合要求'));
                }
            } else {
                callback(new Error('银行卡号只能包含数字'));
            }
        },

        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.judge === false) {
                        //新增
                        request.post("/stu/ad", this.form).then((res) => {
                            console.log(res);
                            if (res.code === "0") {
                                ElMessage({
                                    message: "注册成功",
                                    type: "success",
                                });
                                this.search = "";
                                this.loading = true;
                                this.dialogVisible = false;

                            } else {
                                ElMessage({
                                    message: res.msg,
                                    type: "error",
                                });
                            }
                        });
                    }

                }
            });
        },
        cancel() {
            this.$refs.form.resetFields();
            this.display = {display: "none"};
            this.editJudge = true;
            this.disabled = true;
            this.showpassword = true;
            this.dialogVisible = false;
        },
        EditPass() {
            if (this.editJudge) {
                this.showpassword = false;
                this.display = {display: "flex"};
                this.disabled = false;
                this.editJudge = false;
            } else {
                this.showpassword = true;
                this.display = {display: "none"};
                this.editJudge = true;
                this.disabled = true;
            }
        },


        login() {

            this.$refs.form.validate((valid) => {
                // this.$refs.form.validate((valid) => {
                    // if (valid && this.confirmSuccess) {
                        if (valid) {
                            this.identity = this.form.identity;
                            request.post("/" + this.identity + "/login", this.form).then((res) => {
                                if (res.code === "0") {
                                    ElMessage({
                                        message: "登陆成功",
                                        type: "success",

                                    });
                                    // 登陆成功跳转主页
                                    window.sessionStorage.setItem("user", JSON.stringify(res.data));
                                    window.sessionStorage.setItem("identity", JSON.stringify(this.form.identity));
                                    this.$router.replace({path: "/home"});

                                } else {
                                    ElMessage({
                                        message: res.msg,
                                        type: "error",
                                    });
                                }
                            });
                        }
                        // 验证通过，执行登录逻辑
                        // ...
                    // } else {
                    //     // 验证未通过，或者滑块未完成验证
                    //     // 可以显示错误消息或采取其他操作
                    //     ElMessage({
                    //         message: "未完成验证",
                    //         type: "error",
                    //
                    //     });
                    // }
                // });

            });
        },
        submit(){
            this.isShow = true;
        },

        YG()  {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.judgeAddOrEdit = false;
                this.editDisplay = {display: "none"};
                this.disabled = false;
                this.form = {};
                this.judge = false;
            });
        },
        KF()  {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                this.judgeAddOrEdit = false;
                this.editDisplay = {display: "none"};
                this.disabled = false;
                this.form = {};
                this.judge = false;
            });
        },
        mousedownFn: function (e) {
            if (!this.confirmSuccess) {
                e.preventDefault && e.preventDefault(); // 阻止文字选中等 浏览器默认事件
                this.mouseMoveStata = true;
                this.beginClientX = e.clientX;
            }
        },
        // mousedoen 事件
        // successFunction () {
        //     this.confirmSuccess = true
        //     this.confirmWords = '验证通过';
        //     if (window.addEventListener) {
        //         document.getElementsByTagName('html')[0].removeEventListener('mousemove', this.mouseMoveFn);
        //         document.getElementsByTagName('html')[0].removeEventListener('mouseup', this.moseUpFn);
        //     } else {
        //         document.getElementsByTagName('html')[0].removeEventListener('mouseup', () => {});
        //     }
        //     document.getElementsByClassName('drag_text')[0].style.color = '#fff'
        //     document.getElementsByClassName('handler')[0].style.left = this.maxwidth + 'px';
        //     document.getElementsByClassName('drag_bg')[0].style.width = this.maxwidth + 'px';
        // },
        // 验证成功函数
        // mouseMoveFn (e) {
        //     if (this.mouseMoveStata) {
        //         let width = e.clientX - this.beginClientX;
        //         if (width > 0 && width <= this.maxwidth) {
        //             document.getElementsByClassName('handler')[0].style.left = width + 'px';
        //             document.getElementsByClassName('drag_bg')[0].style.width = width + 'px';
        //         } else if (width > this.maxwidth) {
        //             this.successFunction();
        //             console.log('验证成功');
        //         }
        //     }
        // },
        // mousemove事件
        // moseUpFn (e) {
        //     this.mouseMoveStata = false;
        //     var width = e.clientX - this.beginClientX;
        //     if (width < this.maxwidth) {
        //         document.getElementsByClassName('handler')[0].style.left = 0 + 'px';
        //         document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px';
        //     }
        // }
    },
    // mounted () {
    //     this.maxwidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
    //     document.getElementsByTagName('html')[0].addEventListener('mousemove', this.mouseMoveFn);
    //     document.getElementsByTagName('html')[0].addEventListener('mouseup', this.moseUpFn)
    // },
};