<template>
  <div class="login-container">

    <div style="margin-right: 150px; min-width: 300px">
<!--      <Vcode :show="isShow" @success="success" @close="close" @fail='fail'></Vcode>-->
<!--      <el-button @click="submit">登录</el-button>-->
      <img alt="" src="../../public/login_Team.png" style="width: 600px"/>
    </div>
    <div style="width: 400px; margin: 150px auto">
      <div style="color: black; font-size: 30px; text-align: left; padding: 30px 0">登陆</div>
      <el-form ref="form" :model="form" :rules="rules" size="large">
        <el-form-item prop="username">
          <el-input v-model="form.username" clearable prefix-icon="avatar"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" prefix-icon="lock" show-password></el-input>
        </el-form-item>
        <el-form-item :model="form" prop="identity">
          <el-radio v-model="form.identity" label="stu" style="color: grey">员工</el-radio>
          <el-radio v-model="form.identity" label="dormManager" style="color: grey">派单客服</el-radio>
          <el-radio v-model="form.identity" label="admin" style="color: grey">系统管理员</el-radio>
          <!-- 滑动验证码 -->
<!--          <div ref="dragDiv" class="drag">-->
<!--            <div class="drag_bg"></div>-->
<!--            <div class="drag_text">{{ confirmWords }}</div>-->
<!--            <div ref="moveDiv" :class="{'handler_ok_bg':confirmSuccess}" class="handler handler_bg"-->
<!--                 style="position: absolute;top: 0px;left: 0px;" @mousedown="mousedownFn($event)"></div>-->
<!--          </div>-->
        </el-form-item>
        <el-form-item>
          <el-button :disabled="!disabled" style="width: 20%" type="primary" @click="login" >登 录</el-button>
<!--          <el-button  type="primary" @click="YG">注册</el-button>-->
<!--          <el-button  type="primary" @click="KF">客服注册</el-button>-->
        </el-form-item>

      </el-form>
      <div>
        <!--      弹窗-->
        <el-dialog v-model="dialogVisible" title="操作" width="30%" @close="cancel">
          <el-form ref="form" :model="form" :rules="rules" label-width="120px">
            <el-form-item label="注册身份" prop="type" >
              <el-select v-model="form.type" style="width: 80%" readonly  >
                <el-option label="接单员" value="员工"></el-option>
                <el-option label="派单员" value="客服"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账号" prop="username">
              <el-input v-model="form.username" :disabled="judgeAddOrEdit" style="width: 80%"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="form.password"  :show-password="showpassword"
                        style="width: 80%"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input v-model="form.name"  style="width: 80%"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
              <el-radio v-model="form.gender" label="男">男</el-radio>
              <el-radio v-model="form.gender" label="女">女</el-radio>
            </el-form-item>
            <el-form-item label="手机号" prop="phoneNum">
              <el-input v-model.number="form.phoneNum"  style="width: 80%"
              ></el-input>
            </el-form-item>

            <el-form-item label="身份" prop="identity">
              <el-radio v-model="form.identity" label="设计师" v-if="form.type === '员工'"></el-radio>
              <el-radio v-model="form.identity" label="写手" v-if="form.type === '员工'"></el-radio>
              <el-radio v-model="form.identity" label="分析师" v-if="form.type === '员工'"></el-radio>
              <el-radio v-model="form.identity" label="客服" v-if="form.type === '客服'"></el-radio>
            </el-form-item>
            <el-form-item label="支付宝账号" prop="zhifubao">
              <el-input v-model="form.zhifubao" style="width: 80%"></el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="yhnumber">
              <el-input v-model="form.yhnumber" style="width: 80%"></el-input>
            </el-form-item>

            <el-form-item label="令牌" prop="value">
              <el-input v-model="form.value" style="width: 80%" ></el-input>
            </el-form-item>


          </el-form>
          <template #footer>
              <span class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
              </span>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
  <div style="position: fixed; bottom: 0; width: 100%; text-align: center; color:#939393;">
    <a href="https://beian.miit.gov.cn" style=color:#939393;>辽ICP备2023012818号</a>
  </div>
</template>

<script src="@/assets/js/Login.js"></script>
<style scoped>@import '../assets/css/Login.css';</style>
